import React, { useState } from 'react'

function Services() {



    const [a1, seta1] = useState(false);

    const click=()=>{
        if (a1===false) {
            seta1(true)
        } else {
            seta1(false)
            
        }
    }

  return (
    <div onClick={click}>

        <h2 style={{textAlign:'center'}}>Our Projects</h2>


        <div  className='services' style={{
            display:'grid',
            gridTemplateColumns:'repeat(3,1fr)',
            gridGap:20,
            margin:'20px 8%'

            // flexWrap:'wrap',
            // justifyContent:'space-between'
        }}>

<div style={{borderRadius:8,marginBottom:10,overflow:"hidden",background:'linear-gradient(90deg, rgba(255,214,174,1) 0%, rgba(255,236,209,1) 48%, rgba(255,243,207,1) 100%)',boxShadow:"0px 0px 5px grey"}}>
                <h4 style={{
                    background:'linear-gradient(90deg, rgba(80,40,0,1) 0%, rgba(213,123,0,1) 53%, rgba(255,172,0,1) 100%)',
                    color:'white',
                    padding:'15px 20px',
                    margin:0
                }}><i style={{paddingRight:10}} class="fa-solid fa-paper-plane"></i>Supporting Farmers</h4>
               {a1? <p style={{
                    WebkitBoxOrient: 'vertical',
                    color:'#396e62',
                    padding:'20px 20px',
                    margin:0
                }}>Agromax focuses on providing farmers with advanced agricultural technologies and resources. By offering training, tools, and access to high-quality seeds and fertilizers, Agromax aims to enhance crop yields, reduce costs, and promote sustainable farming practices. This empowers farmers to increase their productivity and income, contributing to overall rural development.</p>:
                <p style={{
                    maxHeight: 40, // adjust this value to fit your needs
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    color:'#396e62',
                    padding:'20px 20px',
                    margin:0
                }}>Agromax focuses on providing farmers with advanced agricultural technologies and resources. By offering training, tools, and access to high-quality seeds and fertilizers, Agromax aims to enhance crop yields, reduce costs, and promote sustainable farming practices. This empowers farmers to increase their productivity and income, contributing to overall rural development.</p>}
            </div>

            <div  style={{borderRadius:8,marginBottom:10,overflow:"hidden",background:'linear-gradient(90deg, rgba(255,214,174,1) 0%, rgba(255,236,209,1) 48%, rgba(255,243,207,1) 100%)',boxShadow:"0px 0px 5px grey"}}>
                <h4 style={{
                    background:'linear-gradient(90deg, rgba(80,40,0,1) 0%, rgba(213,123,0,1) 53%, rgba(255,172,0,1) 100%)',
                    color:'white',
                    padding:'15px 20px',
                    margin:0
                }}><i style={{paddingRight:10}} class="fa-solid fa-house-chimney"></i>Empowering Women in Agriculture</h4>
               { a1?<p style={{
                    WebkitBoxOrient: 'vertical',
                    color:'#396e62',
                    padding:'20px 20px',
                    margin:0,
                    marginBottom:20
                }}>Agromax places a strong emphasis on women empowerment by actively involving women in agricultural activities. The project provides women with opportunities for training, leadership roles, and access to financial resources. By supporting women in agriculture, Agromax helps to foster gender equality, improve household incomes, and enhance community well-being.
</p>:
                <p style={{
                    maxHeight: 40, // adjust this value to fit your needs
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    color:'#396e62',
                    padding:'20px 20px',
                    margin:0,
                    marginBottom:20
                }}>Agromax places a strong emphasis on women empowerment by actively involving women in agricultural activities. The project provides women with opportunities for training, leadership roles, and access to financial resources. By supporting women in agriculture, Agromax helps to foster gender equality, improve household incomes, and enhance community well-being.
</p>}
            </div>

            <div  style={{borderRadius:8,marginBottom:10,overflow:"hidden",background:'linear-gradient(90deg, rgba(255,214,174,1) 0%, rgba(255,236,209,1) 48%, rgba(255,243,207,1) 100%)',boxShadow:"0px 0px 5px grey"}}>
                <h4 style={{
                    background:'linear-gradient(90deg, rgba(80,40,0,1) 0%, rgba(213,123,0,1) 53%, rgba(255,172,0,1) 100%)',
                    color:'white',
                    padding:'15px 20px',
                    margin:0
                }}><i style={{paddingRight:10}} class="fa-brands fa-servicestack"></i>Community Development</h4>
                {a1?<p style={{
                   
                    WebkitBoxOrient: 'vertical',
                    color:'#396e62',
                    padding:'20px 20px',
                    margin:0
                }}>Agromax is dedicated to fostering inclusive growth within communities. The project focuses on building resilient agricultural systems that support all community members, including marginalized groups. Through initiatives like cooperative farming, microfinance, and education programs, Agromax aims to create a self-sustaining ecosystem where all participants can thrive, leading to long-term social and economic benefits.

                </p>:
                <p style={{
                    maxHeight: 40, // adjust this value to fit your needs
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    color:'#396e62',
                    padding:'20px 20px',
                    margin:0
                }}>Agromax is dedicated to fostering inclusive growth within communities. The project focuses on building resilient agricultural systems that support all community members, including marginalized groups. Through initiatives like cooperative farming, microfinance, and education programs, Agromax aims to create a self-sustaining ecosystem where all participants can thrive, leading to long-term social and economic benefits.

                </p>}
            </div>
            {/* <div data-aos="fade-up" style={{width:350,height:350,borderRadius:8,marginBottom:10}}>
                <h4 style={{
                    background:'#396e62',
                    color:'white',
                    padding:'15px 20px',
                    margin:0
                }}>NABL Accredited Commodity Testing Lab</h4>
                <p style={{
                    background:'#EBF1EE',
                    color:'#396e62',
                    padding:'20px 20px',
                    overflow:'scroll',
                    margin:0
                }}>A NABL Accredited Commodity Testing Lab provides precise and reliable testing and certification services for agricultural and other commodities. Accreditation by the National Accreditation Board for Testing and Calibration Laboratories (NABL) ensures adherence to international standards, enhancing the credibility and acceptance of test results.</p>
            </div> */}
            {/* <div data-aos="fade-up" style={{width:400,height:400,borderRadius:8,overflow:'hidden',marginBottom:10,marginRight:30}}>
                <h4 style={{
                    background:'#396e62',
                    color:'white',
                    padding:'15px 20px',
                    margin:0
                }}>Agri Commodity Procurement Services</h4>
                <p style={{
                    background:'#EBF1EE',
                    color:'#396e62',
                    padding:'20px 20px',
                    margin:0
                }}>Agri Commodity Procurement Services assist in sourcing agricultural products directly from farmers or other suppliers. These services aim to ensure the procurement of high-quality commodities at competitive prices, manage supply chain logistics, and maintain inventory levels according to market demand.</p>
            </div> */}

        </div>


        <div className="m0" style={{
        background: 'linear-gradient(90deg, rgba(255,214,174,1) 0%, rgba(255,223,179,1) 48%, rgba(255,243,207,1) 100%)',
        color:'black',
        padding:20,
        margin:'20px 8%',
        borderRadius:8,
    }}>
        <h2><i style={{marginRight:10}} class="fa-brands fa-pagelines"></i>Stay Ahead of the Market with Agromax Warehousing's Exceptional Services</h2>
        <p>We're your trusted ally to outshine the market. Our cutting-edge storage, innovative technology, transparent collateral management, and tailored financial solutions are your recipe for success. With a seasoned team of agri experts, we'll help you navigate the market's twists and turns. Beat the competition and seize opportunities with Agromax Warehousing - where excellence meets agriculture.</p>
    </div>


    <div className="m0" data-aos="fade-up" style={{
        background: 'linear-gradient(90deg, rgba(80,40,0,1) 0%, rgba(213,123,0,1) 53%, rgba(255,172,0,1) 100%)',
        color:'white',
        margin:'20px 8%',
        borderRadius:8,
        overflow:'hidden'
    }}>
        <img style={{
            height:150,
            width:'100%'
        }} src='https://images.pexels.com/photos/461982/pexels-photo-461982.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='img'/>
        <div style={{padding:'10px 20px 20px 20px'}}>
        <h2><i style={{marginRight:10}} class="fa-brands fa-pagelines"></i>Unlocking Agri Excellence</h2>
        <p>At Agromax Warehousing, we're your trusted ally to outshine the market. Our cutting-edge storage, innovative technology, transparent collateral management, and tailored financial solutions are your recipe for success. With a seasoned team of agri experts by your side, we'll help you navigate the market's twists and turns. Beat the competition and seize opportunities with Agromax Warehousing - where excellence meets agriculture.</p>
        </div>
        
    </div>

    
      
    </div>
  )
}

export default Services
