import React from 'react'

function Contactus() {

  const [result, setResult] = React.useState("Send Massage");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "374acb6c-4662-4ff3-8262-9a70e766a8c5");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };





  return (
    <div className='contact m0' style={{
        display: 'flex',
        margin:'20px 8%',
        alignItems:'center',
        background:'linear-gradient(90deg, rgba(255,214,174,1) 0%, rgba(255,223,179,1) 48%, rgba(255,243,207,1) 100%)',
        borderRadius:'10px',
        overflow:'hidden',
        padding:'3%'
    }}>
      <div style={{
        width: '50%',
      }}>
       <img data-aos="fade-right" style={{
            width: '90%',
            margin:20
        }} src='undraw_con.svg' alt='img'/>
      </div>
      <div style={{
        width:'50%',
      }}>
        <form onSubmit={onSubmit}>
        <h2 data-aos="fade-left">Get in touch with Agromax</h2>
        <p data-aos="fade-left">Our representative will reach out to you to share some more information.</p>
        <div style={{gap:20}}>
            <div data-aos="fade-left">
            <p style={{margin:0,fontSize:12,marginBottom:2,fontWeight:"bold"}}>Name</p>
            <input type="text" name="Name" style={{width:250,marginBottom:10}} placeholder='eg. Virendra Singh' required/>
            </div>
            <div data-aos="fade-left">
            <p style={{margin:0,fontSize:12,marginBottom:2,fontWeight:"bold"}}>Email</p>
            <input type="email" name="Email" style={{width:250,marginBottom:10}} placeholder='eg. xyz@gmail.com' required/>
            </div>
            <div data-aos="fade-left">
            <p style={{margin:0,fontSize:12,marginBottom:2,fontWeight:"bold"}}>Massage</p>
            <input type="text" name="Massage" style={{width:250,marginBottom:20}} placeholder='Type Msg.' required/>
            </div>
        </div>
        
        <button data-aos="fade-left" style={{
          background:'#ad6400',
          color:'white',
          padding:'10px 15px',
          borderRadius:5,
          border:0,
          textAlign:'center'
        }} type="submit">{result}</button>

      </form>
        
      </div>
    </div>
  )
}

export default Contactus
