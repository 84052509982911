import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar/Navbar';
import Header from './components/header/Header';
import Services from './components/services/Services';
import About from './components/about/About';
import Contactus from './components/contact/Contactus';
import Footer from './components/footer/Footer';
import { useState } from 'react';
import Team from './components/overteam/team';


function App() {
  const handleDataFromChild = (data) => {
    console.log(data);
    document.getElementById(data).scrollIntoView({ behavior:"smooth"});
  };

  const [Action, setAction] = useState('0')
  return (
    <>
    <div>
    <Navbar scroll={handleDataFromChild} setx={(e)=>setAction(e)}/>
    </div>
    <div id='Header'>
    <Header Action={Action} />
    </div>
    <div  id='Project'>
    <Services />
    </div>
    <div  id='Team'>
    <Team/>
    </div>
    <div id='About'>
    <About />
    </div>
    <div id='Contactus'>
    <Contactus/>
    </div>
    <div id='Navbar'>
    <Footer scroll={handleDataFromChild} setx={(e)=>setAction(e)}/>
    </div>


    </>
  );
}

export default App;
