import React,{useState} from 'react'

function Team() {
    const [data, setdata] = useState({})
    const [show, setshow] = useState(false)

    const contacts=[
        {
            name:"Mr. Hitesh Dhanwani",
            Designation:"Manging Director",
            img:"i1.jpeg",
            msg:"Hitesh Dhanwan, the visionary leader behind Agromax Warehousing, boasting over 10 years of successful business endeavors. As a seasoned entrepreneur, Hitesh Dhanwani has spearheaded numerous dal mills and actively participated in government projects across Rajasthan and Uttar Pradesh. His commitment to excellence and vast expertence shape Agromax Warehousing, propelling us to redefine professional warehousing and contribute to the agricultural growth of the region."
        },
      
        {
            name:"Mr. Akhil Dhanwani",
            Designation:"Director",
            img:"i3.jpeg",
            msg:"Akhil Dhanwani, with over 10 years of experience, brings a unique perspective running successfully businesses in Rajasthan and UP. His entrepreneurial journey includes managing Pulse Mill, Supari business, and Government supply. This hands-on experience adds a practical edge to our leadership team."
        },
        {
            name:"Mr. Harshit Lalwani",
            Designation:"Director",
            img:"image3.png",
            msg:"Harshit Lalwani, with over 10 years of experience, brings a unique perspective running successfully businesses in Rajasthan and UP. His entrepreneurial journey includes managing Pulse Mill, Supari business, and Government supply. This hands-on experience adds a practical edge to our leadership team."
        },
        {
            name:"Mr. Ravindra Sharma",
            Designation:"Head - Business Development & Operation",
            img:"image4.png",
            msg:"Ravindra Sharma brings over 20 years of invaluable experience in the Agricommodity field across different states of India. His expertise has been honed through various positions, making him a seasoned professional in understanding the intricacies of the agricultural supply chain."
        },
        {
            name:"Mr. Anil Arya",
            Designation:"Head - Zonal",
            img:"image5.png",
            msg:"Anil Arya brings to the table 20 years of rich experience in the agri-products trade and business operations. With a commendable track record of 13 years specifically in the agri-commodity sector across various states in India, Anil has held diverse roles in different sectors, showcasing his profound understanding and expertise in the industry."
        },
        {
            name:"Mr. Virendra singh",
            Designation:"state head",
            img:"image6.png",
            msg:"With over nine years of dedicated experience in enhancing the livelihoods of farmers, I have actively contributed to the formation and growth of Farmer Producer Organizations (FIPOs) across multiple states. My work extends to empowering Self Help Groups (SHGs), Joint Liability Groups (LGS), and farmers' clubs, demonstrating a strong commitment to rural development. I have managed warehousing operations for three years, ensuring efficient storage and distribution. As a project head, I have successfully led initiatives under prestigious organizations such as the World Bank, NAFED, and NABARD, showcasing my expertise in procurement, quality control, and technical operations."
        },
        {
            name:"Mr. Dalbeer singh",
            Designation:"Punjab/Hariyana state head",
            img:"i5.jpeg",
            msg:"***"
        },
        {
            name:"Mr. Arvind kumara",
            Designation:"UP state head",
            img:"i4.jpeg",
            msg:"***"
        },
        {
            name:"Mr. Vijay Patil",
            Designation:"State head Mahrastra",
            img:"i6.png",
            msg:"***"
        }
      
      ]
    

  return (
    <>
    <h2 style={{textAlign:'center',marginTop:'40px'}}>Our Team</h2>

    
    <div  style={{
        display:'flex',
        flexWrap:'wrap',  
        justifyContent:'center',
        gap:30,
        margin:'40px 8%',
        }}>

       
        {show&&<div  onClick={()=>setshow(false)}  style={{display:"grid",placeItems:"center",position:"fixed",top:0,left:0,width:"100vw",height:"100vh",background:"#80808033"}}>
            <div style={{width:"400px",background:"white",borderRadius:"10px"}}>
            <div style={{ 
                    color:'black',
                    textAlign:'center',
                    padding:30
                    }}>
            <div >
            <img data-aos="flip-left" style={{
                width:150,
                height:150,
                borderRadius:100,
                marginRight:10
            }} src={data.img} alt='image'/>
            <div>
            <h5 style={{margin:0,fontSize:22}}>{data.name}</h5>
            <p style={{margin:0,fontSize:14}}>{data.Designation}</p>
            </div>
             <p style={{
                fontSize:14
            }}>{data.msg}</p>

        </div>
            </div>
            </div>
        </div>}


        {
            contacts.map(item=>(
                <div onClick={()=>(setdata(item),setshow(true))} style={{ 
                    color:'black',
                    textAlign:'center',
                    width:300
                    }}>
                    <div >
            <img style={{
                width:150,
                height:150,
                borderRadius:100,
                marginRight:10
            }} src={item.img} alt='image'/>
            <div>
            <h5 style={{margin:0,fontSize:22}}>{item.name}</h5>
            <p style={{margin:0,fontSize:14}}>{item.Designation}</p>
            </div>
        </div>

            {/* <p style={{
                color:'white',
                fontSize:14
            }}>{item.msg}</p> */}

                </div>  
            ))
        }
      
    </div>



    <div style={{
        // background: 'linear-gradient(90deg, rgba(240,240,240,1) 0%, rgba(184,226,218,1) 100%)',
        color:'black',
        overflow:'hidden',
        margin:'20px 8%',
        // margin:'30px 0px 20px 0px',
    }}>

        <img style={{
        width:'100%',
        borderRadius:8,

        }} src='agro.webp' alt='img'/>

           

        
    </div>





    </>
  )
}

export default Team
