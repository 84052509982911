import React,{useEffect, useState} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const colors = ["#0088FE", "#00C49F", "#FFBB24"];
const delay = 5000;

function Navbar(props) {
    const [IsOpen, setIsOpen] = useState(false)
    const toggleNavbar = () => {
        setIsOpen(!IsOpen);
      };


const [Action, setAction] = useState('0')
      const click =(e)=>{
        if (e==='v') {
            setAction(Action==='1'?'0':'1')
            props.setx(Action==='1'?'0':'1')
            
        }else if (e==='m') {
            
            setAction(Action==='2'?'0':'2')
            props.setx(Action==='2'?'0':'2')
        }else if (e==='p') {
            
            setAction(Action==='3'?'0':'3')
            props.setx(Action==='3'?'0':'3')
        }else{
            setAction('0')
        }
      }



      

    



 return (
        <div>
            <ToastContainer />
    <nav className="mobile" style={{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding:'0px 8%',
        background: 'linear-gradient(90deg, rgba(80,40,0,1) 0%, rgba(213,123,0,1) 53%, rgba(255,172,0,1) 100%)',
        color:'white',
        height:65,
        fontSize:14,
        borderBottom:'1px solid '
    }}>
        <div style={{
            display:'flex',
            justifyContent:'left',
            alignItems:'center',
            gap:25
        }}>
            <h1 style={{margin:'0px 10px 0px 0px',alignItems:'center',display:'flex'}}><img style={{width:40,marginRight:5,borderRadius:15}} src='flogo.png' alt='img'/>Agromax</h1>
        </div>
        <div>
           {IsOpen?<i onClick={Action==='0'?toggleNavbar:()=>setAction('0')} data-target='slide-up' className="fa fa-xmark"/>:
            <i onClick={toggleNavbar} data-target="slide-down" className="fa fa-bars"/>}
        </div>
      
    </nav>

    {IsOpen&&<div className='navbar' data-target="slide-content" >
           <div style={{textAlign:"center",color:"white",padding:10, background: 'linear-gradient(90deg, rgba(80,40,0,1) 0%, rgba(213,123,0,1) 53%, rgba(255,172,0,1) 100%)'}} >
           <div onClick={()=>window.location.reload()} >
            <i style={{
                margin:5,padding:3
            }} class="fa-solid fa-house"></i>
            <a>Home</a>
            </div>
            <div onClick={()=>{props.scroll('About');click('kk')}}>
            <i style={{
                margin:5,padding:3
            }} class="fa-solid fa-address-card"></i>
            <a>About</a>
            </div>
            <div onClick={()=>click('v')}>
            <i style={{
                margin:5,padding:3
            }} class="fa fa-group"></i>
            <a>Vision</a>
            </div> 
            
            <div onClick={()=>click('m')}>
            <i style={{
                margin:5,padding:3
            }} class="fa fa-bookmark"></i>
            <a>Mission</a>
            </div> 
            <div onClick={()=>{props.scroll('Project');click('kk')}}>
            <i style={{
                margin:5,padding:3
            }} class="fa fa-file"></i>
            <a>Project</a>
            </div>

            <div onClick={()=>click('p')}>
            <i style={{
                margin:5,padding:3
            }} class="fa-solid fa-hand-holding-droplet"></i>
            <a>Services</a>
            </div>

            {/* <div onClick={()=>toast("Coming Soon...")}>
            <i style={{
                margin:5,padding:3
            }} class="fa-solid fa-blog"></i>
            <a>Blog</a>
            </div> */}
            <div style={{margin:5,padding:3}}>
               <a>Call Us  +91- 93407 64314</a>
            </div>
            <div style={{margin:10,padding:3}} onClick={()=>{props.scroll('Contactus');click('kk')}}>
            <a style={{
                borderRadius:8,
                border:'1px solid white',
                padding:'8px 12px',
            }}>Contact Us</a>
            </div>
    </div>
     </div>}
    


    <nav className="desktop"  style={{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding:'0px 8%',
        background: 'linear-gradient(90deg, rgba(80,40,0,1) 0%, rgba(213,123,0,1) 53%, rgba(255,172,0,1) 100%)',
        color:'white',
        height:65,
        fontSize:14,
    }}>
        <div style={{
            display:'flex',
            justifyContent:'left',
            alignItems:'center',
            gap:25
        }}>
            <h1 onClick={()=>window.location.reload()} style={{margin:'0px 10px 0px 0px',alignItems:'center',display:'flex'}}><img style={{width:40,marginRight:5,borderRadius:15}} src='flogo.png' alt='img'/>Agromax</h1>

            <div onClick={()=>window.location.reload()}>
            <i style={{
                marginRight:5
            }} class="fa-solid fa-house"></i>
            <a>Home</a>
            </div>

            <div onClick={()=>{props.scroll('About');click('kk')}}>
            <i style={{
                marginRight:5
            }} class="fa-solid fa-address-card"></i>
            <a>About</a>
            </div>

       
          
            <div onClick={()=>click('v')}>
            <i style={{
                margin:5,padding:3
            }} class="fa fa-group"></i>
            <a>Vision</a>
            </div> 
            
            <div onClick={()=>click('m')}>
            <i style={{
                margin:5,padding:3
            }} class="fa fa-bookmark"></i>
            <a>Mission</a>
            </div> 
            

            {/* <div onClick={()=>scroll('Team')}>
            <i style={{
                margin:5,padding:3
            }} class="fa fa-group"></i>
            <a>Team</a>
            </div>  */}
            <div onClick={()=>click('p')}>
            <i style={{
                marginRight:5
            }} class="fa-solid fa-hand-holding-droplet"></i>
            <a>Services</a>
            </div>

            <div onClick={()=>{props.scroll('Project');click('kk')}}>
            <i style={{
                margin:5,padding:3
            }} class="fa fa-file"></i>
            <a>Project</a>
            </div>
        
           
            {/* <div onClick={()=>toast("Coming Soon...")}>
            <i style={{
                marginRight:5
            }} class="fa-solid fa-blog"></i>
            <a>Blog</a>
            </div> */}
        </div>

        <div>

            <div style={{
            display:'flex',
            justifyContent:'left',
            alignItems:'center',
            gap:25
        }}>
            <a>Call Us  +91- 93407 64314</a>
            <a onClick={()=>{props.scroll('Contactus');click('kk')}} style={{
                borderRadius:8,
                border:'1px solid white',
                padding:'10px 12px',
            }}>Contact Us</a>
            </div>
            
        </div>
      
    </nav>

<Show Action={Action} set={()=>setAction('0')}/>

    </div>

  )

}

const Show=(props)=>{


    const [currentSlide, setCurrentSlide] = useState(0);
    const [slides, setSlides] = useState([
      {url:'https://www.tatahitachiparts.com/img/warehouse/pwh-gallery-03.jpg', image: 'Professional Warehousing (PWH)', alt: 'Professional Warehousing provides secure, efficient, and scalable storage solutions for various types of goods. Key features may include climate control, advanced security systems, inventory management, and real-time tracking.' },
      {url:'https://www.inventiva.co.in/wp-content/uploads/2022/11/service-hero-supply-chain-management-1.jpg', image: 'Warehouse Management Services (WMS)', alt: 'Warehouse Management Services involve the systematic control of warehouse operations. This includes receiving, storing, and shipping goods, optimizing warehouse layout, managing inventory levels, and using warehouse management software (WMS) to enhance operational efficiency.' },
      {url:'https://www.accrels.com/wp-content/uploads/2024/03/QW.jpg', image: 'Audit Outsourcing Services', alt: 'Audit Outsourcing Services offer external audit solutions to ensure compliance with regulatory standards, optimize operational processes, and identify areas for improvement. These services might cover financial audits, compliance audits, and operational audits.' },
      {url:'https://foodtestinginhyderabad.com/wp-content/uploads/nabl-accredited-laboratories-in-hyderabad-300x212.jpg', image: 'NABL Accredited Commodity Testing Lab', alt: 'A NABL Accredited Commodity Testing Lab provides precise and reliable testing and certification services for agricultural and other commodities. Accreditation by the National Accreditation Board for Testing and Calibration Laboratories (NABL) ensures adherence to international standards, enhancing the credibility and acceptance of test results.' },
      {url:'https://www.orrani.com/app/orrani/assets/images/a4f6f7909d37966dd049b4e0aa247e7c.jpg?v=1673604816', image: 'Agri Commodity Procurement Services', alt: 'Agri Commodity Procurement Services assist in sourcing agricultural products directly from farmers or other suppliers. These services aim to ensure the procurement of high-quality commodities at competitive prices, manage supply chain logistics, and maintain inventory levels according to market demand.' },
      // Add more slides as needed
    ]);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      }, 3000); // Adjust the interval time to fit your needs
  
      return () => clearInterval(intervalId);
    }, [slides]);
  

    return(
        <div onClick={()=>props.set()} className='show' style={{
            display:props.Action==="0"?'none':"block",
            position:'absolute',
            top:65,
            left:0,
            right:0,
            height:props.Action!=='0'?500:200,
            background: 'linear-gradient(90deg, rgba(80,40,0,1) 0%, rgba(213,123,0,1) 53%, rgba(255,172,0,1) 100%)',
            overflow:"auto",
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
        }}>

           {props.Action==='1'&& <>
           <h2 data-aos="flip-left" style={{color:'white',textAlign:'center',margin:50}}>OUR VISION</h2>
           <ul data-aos="flip-left" className='fc' style={{display:'flex',gap:20,margin:20,color:'white'}}>
                <li>Excellence in Service Delivery: Agromax Warehousing aspires to be a leading collateral management service provider by consistently delivering exceptional service and innovative solutions that meet and exceed industry standards.
                </li>
                <li>Integrity and Trust: Agromax Warehousing is committed to upholding the highest standards of integrity, ensuring transparent and trustworthy relationships with clients, partners, and stakeholders.
                </li>
                <li>Industry Transformation: Agromax Warehousing aims to be a transformative force in the collateral management sector, pioneering advancements and setting new benchmarks that elevate the entire industry.
                </li>
            </ul>
            </>}

            {props.Action==='2'&& <>
           <h2 data-aos="flip-left" style={{color:'white',textAlign:'center',margin:60}}>OUR MISSION</h2>
           <ul data-aos="flip-left" className='fc' style={{display:'flex',gap:20,margin:20,color:'white'}}>
                <li>Comprehensive Warehousing Solutions: Deliver unparalleled, end-to-end warehousing solutions tailored to meet the diverse needs of our clients.</li>
                <li>Supply Chain Optimization: Empower clients to optimize their supply chains through innovative and efficient warehousing practices.</li>
                <li>Risk Mitigation and Value Maximization: Assist clients in mitigating risks and maximizing value by providing reliable and strategic warehousing services.</li>
            </ul> 
            </>}

            {props.Action==='3'&& <>

            

                {/* <h2 style={{color:'white',textAlign:'center'}}>OUR SERVICE
                </h2> */}


                <div className="slider">
      <div className="slides" style={{
        transform: `translateX(${currentSlide * -100}%)`,
        transition: 'transform 0.5s ease-in-out',
      }}>
        {slides.map((slide, index) => (
          <div key={index} className="slide" style={{
            textAlign:'center',
            backgroundImage:`url(${slide.url})`,
            backgroundSize: 'cover',
            backgroundPosition:'center',
            backgroundRepeat:'no-repeat',
            width:'100%',
            height:500,
            color:'white',
            display:'flex',
            justifyContent:'center',
            alignItems:'end'
          }}>
            {/* <img data-aos="flip-left" style={{width:280,height:150}} src={slide.url} alt='img'/> */}
            <div style={{
                width:'80%',
                background:'#0000007d',
                borderRadius:8,
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                marginBottom:20
            }}>

            <h1 data-aos="flip-left">{slide.image}</h1>
            <p data-aos="flip-left">{slide.alt}</p>
          </div>
          </div>
        ))}
      </div>
    </div>

              </>}

        </div>
    )
}



export default Navbar
