import React from 'react'

function Footer(props) {


    const handleClick = (e) => {
       window.location.href = e;
     };

  return (
    <footer style={{
        backgroundColor: '#a15d00',
        padding:'40px 20px 20px 20px'
    }}>

        <div style={{
            display:'flex',
            flexWrap:'wrap',
            justifyContent:'space-between',
            margin:'0px 7%'
        }}>

        

        {/* <div style={{
            width:'30%',
        }}>

            <h3 style={{
                color:'white',
            }}>Our Team Leaders</h3>
         <div style={{  overflowY:"auto"}}>
        {contacts.map(item=>(<><div style={{
            display: 'flex',
            alignItems:'center',
            color:'white'
        }}>
            <img style={{
                width:40,
                height:40,
                borderRadius:50,
                marginRight:10
            }} src={item.img} alt='image'/>
            <div>
            <h5 style={{margin:0}}>{item.name}</h5>
            <p style={{margin:0,fontSize:10}}>{item.Designation}</p>
            </div>
        </div>

            <p style={{
                color:'white',
                fontSize:14
            }}>{item.msg}</p>
            {item.name!=='Harshit Lalwani'&& <hr/>}
       </> ))}


       </div>
       </div> */}


       <div style={{
            width:'30%',
        }}>

            <h3 style={{
                color:'white',
            }}>Our Collateral Management Partner</h3>
         <div style={{ overflowY:"auto"}}>
   <><div style={{
            // display: 'flex',
            // alignItems:'center',
            color:'white'
        }}>

            <h5 style={{margin:0}}>LTC Commercial Company Pvt. Ltd.</h5>
            <hr/>
            <img style={{
                width:300,
                backgroundColor:'white',
                borderRadius:2,
                marginRight:10,
                padding:20,
                marginTop:10
            }} src='https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjXv326WCndP-7B9HqwZtIhyphenhyphenEN1qjIi8SBm03jz-JJDS0z7oyocp0Bi7kBIipwyjk1BcpMAktAZPoMUpnvuUPKIwUoOhnz4LV2mJJK-gO1VoQFsKiVAn3IkuTjT8CT4ZSWJKOVRESCSz292/s1600/s5_logo.png' alt='image'/>
            <div>
            </div>
        </div>

       </> 


       </div>
       </div>





            {/* <div style={{
                width:'20%'
            }}>
                <h3 style={{color:'white'}}>About Us</h3>

                <ul style={{listStyleType:'none',padding:0,margin:0}}>
                    <li onClick={()=>props.scroll('Header')} style={{color:'white'}}>Home</li>
                    <li onClick={()=>props.scroll('About')} style={{color:'white',margin:'15px 0px'}}>About</li>
                    <li onClick={()=>props.scroll('Services')} style={{color:'white',margin:'15px 0px'}}>Services</li>
                    <li style={{color:'white',margin:'15px 0px'}}>Blog</li>
                    <li style={{color:'white'}}>Help</li>
                </ul>
                
            </div> */}


            <div style={{
                width:'20%'
            }}>
                <h3 style={{color:'white'}}>Company</h3>

                <ul style={{listStyleType:'none',padding:0,margin:0}}>
      <img style={{width:50,marginRight:5,borderRadius:15}} src='flogo.png' alt='img'/>
      <li></li>
                    <li onClick={()=>handleClick("https://privacy-policy.agromaxwarehousing.com")} style={{color:'white'}}>Privacy Policy</li>
                    <li onClick={()=>handleClick("https://terms-conditions.agromaxwarehousing.com")} style={{color:'white',margin:'15px 0px'}}>Terms & Conditions</li>
                    {/* <li style={{color:'white',margin:'15px 0px'}}>H-1-36, Gokul Industries, Badrana VKI area, Jaipur, Rajasthan, Bharat (In). 302013
                    </li> */}
                </ul>

                {/* <hr/>
                <a onClick={()=>handleClick("https://www.freeprivacypolicy.com/live/5fb87d28-7f63-45dd-8c96-9c33c79a0e9c")} style={{
            color:'white',
            fontSize:12,
            textAlign:'center'
        }}><i class="fa-solid fa-shield-halved" style={{marginRight:8}}></i>Privacy Policy </a> */}
            </div>

            <div style={{
                width:'30%'
            }}>
                <h3 style={{color:'white'}}>Contact Us</h3>

                <ul style={{listStyleType:'none',padding:0,margin:0}}>
                    <li style={{color:'white'}}>+91- 93407 64314</li>
                    <li style={{color:'white',margin:'15px 0px'}}>business@agromaxwerehousing.com</li>
                    <li style={{color:'white',margin:'15px 0px'}}>H-1-36, Gokul Industries, Badrana VKI area, Jaipur, Rajasthan, Bharat (In). 302013
                    </li>
                </ul>

                {/* <hr/>
                <a onClick={()=>handleClick("https://www.freeprivacypolicy.com/live/5fb87d28-7f63-45dd-8c96-9c33c79a0e9c")} style={{
            color:'white',
            fontSize:12,
            textAlign:'center'
        }}><i class="fa-solid fa-shield-halved" style={{marginRight:8}}></i>Privacy Policy </a> */}
            </div>


            

            </div>
            




        <div style={{
                width:'100%',
                background:'white',
                height:1,
                margin:'20px 0px'
            }}></div>

            <div style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                gap:20,
                color:'white'
            }}>
            <i onClick={()=>handleClick("https://www.linkedin.com/in/agromax-warehousing-pvt-ltd-88b502320")} class="fa-brands fa-linkedin"></i>
            <i onClick={()=>handleClick("https://www.instagram.com/agromaxwarehousing/")} class="fa-brands fa-instagram"></i>
            <i onClick={()=>handleClick("https://x.com/Agromaxpvtltd")} class="fa-brands fa-twitter"></i>
            <i onClick={()=>handleClick("https://m.facebook.com/profile.php?id=61563272462067&name=xhp_nt__fb__action__open_user")} class="fa-brands fa-facebook"></i>
            <i onClick={()=>handleClick("https://api.whatsapp.com/send/?phone=%2B918058790036&text&type=phone_number&app_absent=0")} class="fa-brands fa-whatsapp"></i>
            </div>

        <div style={{
                width:'100%',
                background:'white',
                height:1,
                margin:'20px 0px'
            }}></div>

        
        <p style={{
            color:'white',
            fontSize:12,
            textAlign:'center'
        }}>Copyright © 2024 | Agromax Warehousing Pvt. Ltd. | All rights Reserved.</p>
      
    </footer>
  )
}

export default Footer
